import React, { useState } from 'react'
import 'twin.macro'
import { useForm } from 'react-hook-form'

import { ErrorMessage, ErrorMessageText, Form, FormButton, FormField, FormSection, TextArea } from '../../components/elements'
import { ROUTES } from '../../components/layout/Navigation'
import { OnboardingStep, useMatchOnboardingState } from '../../content/onboarding/OnboardingStep'
import { useNavigate } from '@reach/router'
import { Spinner } from '../../components/elements/Loading'

type FormResults = {
  details: string
}

const OnboardingStep2 = () => (

  <OnboardingStep
    step={2}
    desktopTitle={'2. Describe your situation'}
    imageUrl='https://images.unsplash.com/photo-1523995462485-3d171b5c8fa9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=675&q=80'
  >
    <Step2Form />
  </OnboardingStep>
)

const Step2Form = () => {
  const navigate = useNavigate()
  const { actions, state } = useMatchOnboardingState()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const { register, handleSubmit, errors } = useForm<FormResults>({
    defaultValues: state
  })

  const onSubmit = async (data: FormResults) => {
    actions.update(data)
    const payload = {
      details: data.details,
      secret: state.secret,
      submissionId: state.submissionId,
      record: state.record
    }

    setError(false)
    setLoading(true)

    const response = await fetch('/.netlify/functions/match-onboarding-add-details', {
      method: 'POST',
      body: JSON.stringify(payload)
    })

    setLoading(false)

    if (!response.ok) {
      setError(true)
    } else {
      navigate(ROUTES.onboarding.step3)
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} tw="sm:p-12">

      <div tw="bg-indigo-50 text-indigo-500 rounded-md p-4 text-xs sm:text-sm leading-4 sm:leading-5 mb-4">
        <p tw="font-medium mb-2">
        Please tell us about the case including the amount you are asking to be refunded and any relevant dates.
        </p>
        <p>
          Please do not enter confidential infomation such as email addresses or phone numbers.
        </p>
      </div>

      <FormField
        id={'details'}
        label={'Your Situation'}
        error={errors.details}
        control={(
          <TextArea
            ref={register({ required: "This field is required" })}
            name="details"
            id="details"
            placeholder=""
            rows={8}
          />
        )}
      />

      <FormSection tw="mt-4">
        <FormButton disabled={loading}>
        {loading ? <Spinner tw="h-6" /> : <>Find your solicitor now</>}
        </FormButton>
      </FormSection>

      {error && (
        <ErrorMessage tw="mb-4" title={'Something went wrong'}>
          <ErrorMessageText>
            Please try again or contact <a href="mailto:support@lawletter.co">support@lawletter.co</a>
          </ErrorMessageText>
        </ErrorMessage>
      )}

    </Form>
  )
}

export default OnboardingStep2
